// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
 production: true,
  // apiBaseUrl: 'https://devadmin.kiddyswap.com',
  // apiBaseUrlSocket: 'https://devsockets.kiddyswap.com',
  apiBaseUrl: 'https://ecommerce-backend.netsolutionindia.com',
  apiBaseUrlSocket: 'https://ecommerce-sockets.netsolutionindia.com',
  firebase: {
    apiKey: "AIzaSyAIkAm1qmu0LzFtWEaHYV-BDq8EUPK0Cz0",
    authDomain: "tutulist.firebaseapp.com",
    projectId: "tutulist",
    storageBucket: "tutulist.appspot.com",
    messagingSenderId: "899178810610",
    appId: "1:899178810610:web:03180ee00a5d383b9bd46b",
    measurementId: "G-83XR1FXB01"
  },
  stripeKey: 'pk_test_CGU4v5K7dzyLAzomPQ47jlpB'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
