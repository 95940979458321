import { MessagingService } from '../message/message.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private message: MessagingService,
    private util: UtilityService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap(event => { }, error => {
          switch (error.status) {
            case 401:
              /********** Auto logout if 401 response returned from api **********/
              this.util.setLogout(true);
              // this.message.alert('error', 'OOPS!', 'Sorry, Please login again to continue.');
              break;
            case (0 || 444):
              break;
            default:
              /********** Check for other serve-side errors **********/
              if (error.error.msg) {
                this.message.alert('error', error.error.msg);
              }
              break;
          }
        }));
  }

}
