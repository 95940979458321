// imports
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

// declarations
import { AppComponent } from './app.component';
import { LoaderComponent } from './loader/loader.component';

// providers
import { WINDOW_PROVIDERS } from './services/window/window.service';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';
import { TransferStateInterceptor } from './services/interceptors/transferState.interceptor';

// firebase
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AppLoadService } from './services/app-load/app-load.service';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'kiddyswap' }),
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFactory,
      deps: [AppLoadService,],
      multi: true,
      
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferStateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    WINDOW_PROVIDERS
  ],
})
export class AppModule { }

export function initAppFactory(app_load_service: AppLoadService) {
  return () => app_load_service.init();
}
