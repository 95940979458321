import { Injectable } from '@angular/core';
import { GlobalVariable } from './../../../app/core/global';
import { UtilityService } from '../utility/utility.service';
import { ScriptService } from '../script/script.service';
import { ApiKeys } from './../../core/apiKeys.model';

@Injectable({
  providedIn: 'root'
})
export class AppLoadService {

  apiKeys: ApiKeys;

  constructor(
    public util: UtilityService,
    private script: ScriptService
  ) { }

  init() {
    this.apiKeys = new ApiKeys();
    this.setApiKeys();
  }

  setApiKeys(): void {
    this.apiKeys.google_maps = GlobalVariable.googleMaps_apiKey;
    this.util.setKeys(this.apiKeys);

    //Load Api Scripts
    this.script.load('googleMaps').then(data => {
      let check = data.find(element => {
        return element['script'] === 'googleMaps';
      });
      if (check.status === 'Loaded') {
        this.util.settingsLoaded.next(true);
      }
    });
  }

}