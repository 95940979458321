export const GlobalVariable = {

	SITE_NAME: "TutuList",
	PATTERNS: {
		email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
		password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
	},
	token: 'kiddyswap_website',
	facebookId: '1901377336724676',
	socialId: 'socialId',
	role: 'user_role',
	routeKey: 'kiddy_route',
	userDetail: 'user_detail',
	cartAddess: 'kiddy_cart_address',
	cartPromo: 'kiddy_cart_promo',
	googleMaps_apiKey: 'AIzaSyBcnyPYmDzwwCRCEWCqtddswnKoFQzoZDI',

	user_location: 'kiddy_user_location',
	user_country: 'kiddy_country',
	default_country: 'en_US',

}
