import { NgModule } from '@angular/core';

// module
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// service
import { SelectivePreloadingService } from './services/selective-preloading/selective-preloading.service';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    data: { preload: true, delay: false }
  },
  {
    path: 'register', loadChildren: () => import('./login-signup-module/login-signup-module.module').then(m => m.LoginSignupModuleModule),
    data: { preload: false, delay: false }
  },
  {
    path: 'app-about', loadChildren: () => import('./layout/common/footer/footer-pages/about/about.module').then(m => m.AboutModule),
    data: {
      title: 'About Us',
      description: 'About Us',
      keyword: 'About Us', preload: false, delay: false
    }
  },
  {
    path: 'app-how-it-works', loadChildren: () => import('./layout/common/footer/footer-pages/how-it-works/how-it-works.module').then(m => m.HowItWorksModule),
    data: {
      title: 'How It Works',
      description: 'How It Works',
      keyword: 'How It Works', preload: false, delay: false
    }
  },
  {
    path: 'app-affilate', loadChildren: () => import('./layout/common/footer/footer-pages/affilate/affilate.module').then(m => m.AffilateModule),
    data: {
      title: 'Affilate',
      description: 'Affilate',
      keyword: 'Affilate', preload: false, delay: false
    }
  },
  {
    path: 'app-affilate-form', loadChildren: () => import('./layout/common/footer/footer-pages/affilate-form/affilate-form.module').then(m => m.AffilateFormModule),
    data: {
      title: 'Affilate Form',
      description: 'Affilate Form',
      keyword: 'Affilate Form', preload: false, delay: false
    }
  },
  {
    path: 'app-recent-news', loadChildren: () => import('./layout/common/footer/footer-pages/recent-news/recent-news.module').then(m => m.RecentNewsModule),
    data: {
      title: 'Recent News',
      description: 'Recent News',
      keyword: 'Recent News', preload: false, delay: false
    }
  },
  {
    path: 'app-help', loadChildren: () => import('./layout/common/footer/footer-pages/help/help.module').then(m => m.HelpModule),
    data: {
      title: 'Help',
      description: 'Help',
      keyword: 'Help', preload: false, delay: false
    }
  },
  {
    path: 'app-help-ca', loadChildren: () => import('./layout/common/footer/footer-pages/help-canada/help-canada.module').then(m => m.HelpCanadaModule),
    data: {
      title: 'Help CANADA',
      description: 'Help CANADA',
      keyword: 'Help', preload: false, delay: false
    }
  },
  
  {
    path: 'app-blogs', loadChildren: () => import('./layout/common/footer/footer-pages/blogs/blogs.module').then(m => m.BlogsModule),
    data: {
      title: 'Blogs',
      description: 'Blogs',
      keyword: 'Blogs'
    }
  },
  {
    path: 'app-terms-conditions', loadChildren: () => import('./layout/common/footer/footer-pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
    data: {
      title: 'Terms Conditions',
      description: 'Terms Conditions',
      keyword: 'Terms Conditions'
    }
  },
  {
    path: 'app-policies', loadChildren: () => import('./layout/common/footer/footer-pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    data: {
      title: 'Privacy Policy',
      description: 'Privacy Policy',
      keyword: 'Privacy Policy'
    }
  },

  {
    path: '**', redirectTo: '', pathMatch: 'full'
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabled', preloadingStrategy: SelectivePreloadingService }),
    CommonModule
  ],
  exports: [RouterModule],
})

export class AppRoutingModule { }

