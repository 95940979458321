import { GlobalVariable } from './core/global';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from './services/seo/seo.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private _seoService: SeoService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: string
  ) { }

  ngOnInit() {
    this.metaData();
    this.removeSplash();
  }

  removeSplash() {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById("splash_screen").style.display = "none";
    }
  }

  metaData() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      let tab_name = event['title'] ? GlobalVariable.SITE_NAME + ' | ' + event['title'] : GlobalVariable.SITE_NAME;
      this._seoService.updateTitle(tab_name);
      this._seoService.updateKeyWords(event['keyword']);
      this._seoService.updateDescription(event['title'] + ' | ' + event['description']);
      this._seoService.updateImage('/assets/images/big_logo.png');
      // document.getElementById('fullBody').classList.remove('fullBody');
    });
  }

}
