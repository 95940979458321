import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilityService } from '../utility/utility.service';
import { GlobalVariable } from '../../core/global';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    public util: UtilityService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /********** add authorization header with jwt token if available **********/
    const token = this.util.getToken();
    const country = this.util.getLocalData(GlobalVariable.user_country);
    
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'X-localization': country ? country : 'en_US',
          'X-device': 'web'
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          'X-localization': country ? country : 'en_US',
          'X-device': 'web'
        }
      });
    }

    return next.handle(request);
  }

}
