
import { LOCAL_STORAGE, WINDOW } from "@ng-toolkit/universal";
import { Injectable, Inject } from "@angular/core";
import { Location } from "@angular/common";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiKeys } from '../../core/apiKeys.model';
import { GlobalVariable } from '../../core/global';

declare const $: any;

@Injectable({
  providedIn: "root"
})
export class UtilityService {

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    @Inject(WINDOW) public window: Window,
    private _location: Location
  ) { }

  getToken() {
    // alert('outside get token')
    // alert(this.localStorage.getItem(GlobalVariable.token))
    if (this.localStorage.getItem(GlobalVariable.token)) {
      // alert(this.localStorage.getItem(GlobalVariable.token))
      return this.localStorage.getItem(GlobalVariable.token)
    } else {
      // alert('null token')
      // alert(this.localStorage.getItem(GlobalVariable.token))
      return '';
    }
  }

  setToken(data) {
    // alert('set token');
    // alert(data);
    this.localStorage.setItem(GlobalVariable.token, data);
    // alert('check token add');
    // alert(this.localStorage.getItem(GlobalVariable.token));
  }

  /********* Set data in local storage **********/
  setLocalData(key: string, data: any, json?: boolean) {
    this.localStorage.setItem(key, json ? JSON.stringify(data) : data);
  }

  getUserId() {
    let data = this.getLocalData(GlobalVariable.userDetail, true);
    return data.id;
  }

  /********* Get data from local storage **********/
  getLocalData(key: string, json?: boolean) {
    let _data: any = null;
    try {
      _data = json
        ? JSON.parse(this.localStorage.getItem(key))
        : this.localStorage.getItem(key);
      return _data;
    } catch (error) {
      if (error instanceof SyntaxError) this.clearLocalData(key);
      return null;
    }
  }

  clearAllLocalData() {
    this.localStorage.clear();
  }

  /********* Remove data from local storage **********/
  clearLocalData(key: string) {
    this.localStorage.removeItem(key);
  }

  goBack() {
    this._location.back();
  }

  goToTop() {
    this.window.scrollTo(0, 0);
  }

  /********* Share Key data **********/
  private key: BehaviorSubject<ApiKeys> = new BehaviorSubject(null);
  getKeys: Observable<ApiKeys> = this.key.asObservable();
  setKeys(data: ApiKeys) {
    this.key.next(data);
  }

  private fcmSubject = new Subject<any>();
  fireBaseNotification = this.fcmSubject.asObservable();
  updatePushData(data) {
    this.fcmSubject.next(data);
  }

  // Settings change call function
  settingsLoaded = new BehaviorSubject(false);

  cal(page, limit, count) {
    if (page * limit <= count) {
      return page * limit;
    } else {
      return count;
    }
  }

  /********* Share Key data **********/
  private categories: BehaviorSubject<any> = new BehaviorSubject(null);
  getCategories: Observable<any> = this.categories.asObservable();
  setCategories(data) {
    this.categories.next(data);
  }

  /********* Share Key data **********/
  private products: BehaviorSubject<any> = new BehaviorSubject(null);
  getProducts: Observable<any> = this.products.asObservable();
  setProducts(data) {
    this.products.next(data);
  }

  /********* Share Key data **********/
  private deals: BehaviorSubject<any> = new BehaviorSubject(null);
  getDeals: Observable<any> = this.deals.asObservable();
  setDeals(data) {
    this.deals.next(data);
  }

  /********* cart Key data **********/
  private cart: BehaviorSubject<any> = new BehaviorSubject(null);
  getCart: Observable<any> = this.cart.asObservable();
  setCart(data) {
    this.cart.next(data);
  }

  /********* cart Key data **********/
  private logoutSub: BehaviorSubject<any> = new BehaviorSubject(null);
  logout: Observable<any> = this.logoutSub.asObservable();
  setLogout(value) {
    this.logoutSub.next(value);
  }

  cartChanged = new Subject();
  updateProfile = new Subject();

  locationUpdated = new Subject();
  countrySubject = new Subject();

  userLocationCall(place) {
    let location_obj = {};
    for (let i in place.address_components) {
      let item = place.address_components[i];

      location_obj['formatted_address'] = place.formatted_address;
      if (item['types'].indexOf("locality") > -1) {
        location_obj['locality'] = item['long_name']
      } else if (item['types'].indexOf("administrative_area_level_1") > -1) {
        location_obj['admin_area_l1'] = item['short_name']
      } else if (item['types'].indexOf("street_number") > -1) {
        location_obj['street_number'] = item['short_name']
      } else if (item['types'].indexOf("route") > -1) {
        location_obj['route'] = item['long_name']
      } else if (item['types'].indexOf("country") > -1) {
        location_obj['country'] = item['long_name']
      } else if (item['types'].indexOf("postal_code") > -1) {
        location_obj['postal_code'] = item['short_name']
      }
    }
    location_obj['name'] = place.name;
    if (place.geometry) {
      location_obj['lat'] = place.geometry.location.lat();
      location_obj['lng'] = place.geometry.location.lng();
    }
    return location_obj;
  }

}
