import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title, 
    private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateKeyWords(keyword: string) {
    this.meta.updateTag({ name: 'keyword', content: keyword });
  }

  updateImage(image: string) {
    this.meta.updateTag({ name: 'twitter:image', content: image });
  }

  // updateOgUrl(url: string) {
  //   this.meta.updateTag({ name: 'og:url', content: url })
  // }
}
